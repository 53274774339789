let buttonOpen = document.querySelectorAll('.show-tab');
let buttonClose = document.querySelector('.close');
let buttonHidden = document.querySelector('.hidden-btn')
let tab = document.querySelector('.whatisthis-content');
let notify = document.querySelector('.notification-mess');
let notifyButton = document.querySelector('.popup-link');

// Open Tab
for (let i = 0; i < buttonOpen.length; i++) {
    buttonOpen[i].addEventListener("click", function(e) {
        e.preventDefault();
        tab.classList.toggle('show');
    })
}

// All Closed Buttons
notifyButton.addEventListener('click', function(){
    notify.classList.add('hidden');
});

buttonClose.addEventListener("click", function() {
    buttonClose.classList.toggle('open');
    tab.classList.toggle('show');
    notify.classList.toggle('hidden');
});

buttonHidden.addEventListener("click", function() {
 notify.classList.add('hidden');
});

// Scroll Inputs
window.onload = function() {
 let errorFields = document.querySelectorAll('.invalid-feedback, .notify');
 if (errorFields.length > 0) {
     let firstErrorField = errorFields[0];
     let scrollPos = firstErrorField.getBoundingClientRect().top + window.scrollY - 100;
     window.scrollTo({ top: scrollPos, behavior: 'smooth' });
 }
};


/* --------------------------------------------------------------------- */
/* Home slider                                                           */
/* --------------------------------------------------------------------- */
jQuery(document).ready(function($){
    var sliderWrapper = $('.slider-winner-wrapper');
    var prevBtn = sliderWrapper.find('.prev');
    var nextBtn = sliderWrapper.find('.next');
    var list = sliderWrapper.find('.slider-winner-list');
    var items = list.find('.slider-winner-item');
    var currentItem = 0;
    var animationInProgress = false;

    if (!sliderWrapper.length) {
        return
    }

    nextBtn.on('click', function(e) {
        e.preventDefault();

        if (animationInProgress) {
            return;
        }

        currentItem++;
        animationInProgress = true;

        if (currentItem >= items.length) {
            currentItem = 0;

        }

        list.css('margin-left', (-100 * currentItem) + "%");
        items.addClass('is-active');

        setTimeout(function() {
            animationInProgress = false;
            items.removeClass('is-active');
        }, 600);
    });

    prevBtn.on('click', function(e) {
        e.preventDefault();

        if (animationInProgress) {
            return;
        }

        currentItem--;
        animationInProgress = true;

        if (currentItem < 0) {
            currentItem = items.length - 1;
        }

        list.css('margin-left', (-100 * currentItem) + "%");
        items.addClass('is-active');

        setTimeout(function() {
            animationInProgress = false;
            items.removeClass('is-active');
        }, 600);
    });
});
